import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import VeeValidatePlugin from "@/core/plugins/validation";

import "@/core/plugins/prismjs";
const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(VeeValidatePlugin);
app.component("VueDatePicker", VueDatePicker);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);

app.use(i18n);

app.mount("#app");
