import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { useAppStore } from "@/store/app/StoreApp";

const routes = [
  {
    name: "/",
    path: "/",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      //dashboards
      {
        path: "/dashboard/dashboard",
        name: "dashboard-dashboard",
        component: () => import("@/views/dashboards/DashboardView.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboard", "Dashboard"],
        },
      },
      //setting route
      {
        path: "/setting/module",
        name: "setting-module",
        component: () => import("@/views/settings/module/ModuleView.vue"),
        meta: {
          pageTitle: "Module List",
          breadcrumbs: ["Setting", "Data Module"],
        },
      },
      {
        path: "/setting/sub-module",
        name: "setting-sub-module",
        component: () =>
          import("@/views/settings/sub-module/SubModuleView.vue"),
        meta: {
          pageTitle: "Sub Module List",
          breadcrumbs: ["Setting", "Data Sub Module"],
        },
      },
      {
        path: "/setting/menu",
        name: "setting-menu",
        component: () => import("@/views/settings/menu/MenuView.vue"),
        meta: {
          pageTitle: "Menu List",
          breadcrumbs: ["Setting", "Data Menu"],
        },
      },
      {
        path: "/setting/user",
        name: "setting-user",
        component: () => import("@/views/settings/user/UserView.vue"),
        meta: {
          pageTitle: "User List",
          breadcrumbs: ["Setting", "Data User"],
        },
      },
      {
        path: "/setting/role",
        name: "setting-role",
        component: () => import("@/views/settings/role/RoleView.vue"),
        meta: {
          pageTitle: "User Role",
          breadcrumbs: ["Setting", "Data User Role"],
        },
      },
      {
        path: "/setting/access",
        name: "setting-access",
        component: () => import("@/views/settings/access/AccessView.vue"),
        meta: {
          pageTitle: "Role Access",
          breadcrumbs: ["Setting", "Data Role Access"],
        },
      },
      {
        path: "/setting/masters",
        name: "setting-masters",
        component: () => import("@/views/settings/master/MasterView.vue"),
        meta: {
          pageTitle: "Master Data",
          breadcrumbs: ["Setting", "Master Data"],
        },
      },
      //PROPERTY ROUTE
      {
        path: "/property/property",
        name: "property-property",
        component: () => import("@/views/properties/property/PropertyView.vue"),
        meta: {
          pageTitle: "Data Property",
          breadcrumbs: ["Property", "Data Property"],
        },
      },
      {
        path: "/property/property-form/:id?",
        name: "property-property-form",
        component: () => import("@/views/properties/property/PropertyForm.vue"),
        meta: {
          pageTitle: "Form Property",
          breadcrumbs: ["Property", "Form Property"],
        },
      },
      {
        path: "/property/pending",
        name: "property-pending",
        component: () =>
          import("@/views/properties/property-pending/PropertyPendingView.vue"),
        meta: {
          pageTitle: "Data Property Butuh Moderasi",
          breadcrumbs: ["Property", "Butuh Moderasi"],
        },
      },
      {
        path: "/property/archive",
        name: "property-archive",
        component: () =>
          import("@/views/properties/property-archive/PropertyArchiveView.vue"),
        meta: {
          pageTitle: "Data Property Arsip/Tidak Aktif",
          breadcrumbs: ["Property", "Arsip/Tidak Aktif"],
        },
      },
      {
        path: "/property/archive-form/:id?",
        name: "property-archive-form",
        component: () =>
          import("@/views/properties/property-archive/PropertyArchiveForm.vue"),
        meta: {
          pageTitle: "Form Property Arsip",
          breadcrumbs: ["Property", "Form Property Arsip"],
        },
      },
      {
        path: "/property/master-electricity",
        name: "property-master-electricity",
        component: () =>
          import(
            "@/views/properties/master-electricity/MasterElectricityView.vue"
          ),
        meta: {
          pageTitle: "Data Daya Listrik",
          breadcrumbs: ["Property", "Data Daya Listrik"],
        },
      },
      {
        path: "/property/master-facing",
        name: "property-master-facing",
        component: () =>
          import("@/views/properties/master-facing/MasterFacingView.vue"),
        meta: {
          pageTitle: "Data Master Hadap",
          breadcrumbs: ["Property", "Data Master Hadap"],
        },
      },
      {
        path: "/property/master-category",
        name: "property-master-category",
        component: () =>
          import("@/views/properties/master-category/MasterCategoryView.vue"),
        meta: {
          pageTitle: "Data Master Kategori",
          breadcrumbs: ["Property", "Data Master Kategori"],
        },
      },
      {
        path: "/property/master-facility",
        name: "property-master-facility",
        component: () =>
          import("@/views/properties/master-facility/MasterFacilityView.vue"),
        meta: {
          pageTitle: "Data Master Fasilitas",
          breadcrumbs: ["Property", "Data Master Fasilitas"],
        },
      },
      {
        path: "/property/master-condition",
        name: "property-master-condition",
        component: () =>
          import("@/views/properties/master-condition/MasterConditionView.vue"),
        meta: {
          pageTitle: "Data Master Kondisi",
          breadcrumbs: ["Property", "Data Master Kondisi"],
        },
      },
      {
        path: "/property/master-furniture",
        name: "property-master-furniture",
        component: () =>
          import("@/views/properties/master-furniture/MasterFurnitureView.vue"),
        meta: {
          pageTitle: "Data Master Furniture",
          breadcrumbs: ["Property", "Data Master Furniture"],
        },
      },
      {
        path: "/property/master-certificate",
        name: "property-master-certificate",
        component: () =>
          import(
            "@/views/properties/master-certificate/MasterCertificateView.vue"
          ),
        meta: {
          pageTitle: "Data Master Sertifikat",
          breadcrumbs: ["Property", "Data Master Sertifikat"],
        },
      },
      {
        path: "/property/master-province",
        name: "property-master-province",
        component: () =>
          import("@/views/properties/master-province/MasterProvinceView.vue"),
        meta: {
          pageTitle: "Data Master Provinsi",
          breadcrumbs: ["Property", "Data Master Provinsi"],
        },
      },
      {
        path: "/property/master-city",
        name: "property-master-city",
        component: () =>
          import("@/views/properties/master-city/MasterCityView.vue"),
        meta: {
          pageTitle: "Data Master Kota",
          breadcrumbs: ["Property", "Data Master Kota"],
        },
      },
      {
        path: "/property/master-district",
        name: "property-master-district",
        component: () =>
          import("@/views/properties/master-district/MasterDistrictView.vue"),
        meta: {
          pageTitle: "Data Master Kecamatan",
          breadcrumbs: ["Property", "Data Master Kecamatan"],
        },
      },
      {
        path: "/property/master-village",
        name: "property-master-village",
        component: () =>
          import("@/views/properties/master-village/MasterVillageView.vue"),
        meta: {
          pageTitle: "Data Master Kelurahan/Desa",
          breadcrumbs: ["Property", "Data Master Kelurahan/Desa"],
        },
      },
      //MEMBER ROUTE
      {
        path: "/member/member",
        name: "member-member",
        component: () => import("@/views/members/member/MemberView.vue"),
        meta: {
          pageTitle: "Data Member",
          breadcrumbs: ["Member", "Data Member"],
        },
      },
      {
        path: "/member/member-form/:id?",
        name: "member-member-form",
        component: () => import("@/views/members/member/MemberForm.vue"),
        meta: {
          pageTitle: "Form Member",
          breadcrumbs: ["Member", "Form Member"],
        },
      },
      {
        path: "/member/master-type",
        name: "member-master-type",
        component: () =>
          import("@/views/members/master-type/MasterTypeView.vue"),
        meta: {
          pageTitle: "Data Master Tipe",
          breadcrumbs: ["Property", "Data Master Tipe"],
        },
      },
      {
        path: "/member/master-package",
        name: "member-master-package",
        component: () =>
          import("@/views/members/master-package/MasterPackageView.vue"),
        meta: {
          pageTitle: "Data Master Paket",
          breadcrumbs: ["Property", "Data Master Paket"],
        },
      },
      //WEBSITE ROUTE
      {
        path: "/website/banner",
        name: "website-banner",
        component: () => import("@/views/websites/banner/BannerView.vue"),
        meta: {
          pageTitle: "Data Banner",
          breadcrumbs: ["Website", "Data Banner"],
        },
      },
      {
        path: "/website/news",
        name: "website-news",
        component: () => import("@/views/websites/news/NewsView.vue"),
        meta: {
          pageTitle: "Data News/Artikel",
          breadcrumbs: ["Website", "News/Artikel"],
        },
      },
      {
        path: "/website/news-form/:id?",
        name: "news-form",
        component: () => import("@/views/websites/news/NewsForm.vue"),
        meta: {
          pageTitle: "Form News/Artikel",
          breadcrumbs: ["Website", "Form News/Artikel"],
        },
      },
      {
        path: "/website/campaigns",
        name: "website-campaigns",
        component: () => import("@/views/websites/campaign/CampaignView.vue"),
        meta: {
          pageTitle: "Data Campaign",
          breadcrumbs: ["Website", "Data Campaign"],
        },
      },
      {
        path: "/website/faq",
        name: "website-faq",
        component: () => import("@/views/websites/faq/FaqView.vue"),
        meta: {
          pageTitle: "Data Faq",
          breadcrumbs: ["Website", "Faq"],
        },
      },
      {
        path: "/website/faq-form/:id?",
        name: "faq-form",
        component: () => import("@/views/websites/faq/FaqForm.vue"),
        meta: {
          pageTitle: "Form Faq",
          breadcrumbs: ["Website", "Form Faq"],
        },
      },
      {
        path: "/website/about-us",
        name: "about-us-form",
        component: () => import("@/views/websites/about-us/AboutUsForm.vue"),
        meta: {
          pageTitle: "Form About Us",
          breadcrumbs: ["Website", "Form About Us"],
        },
      },
      {
        path: "/website/terms-condition",
        name: "terms-condition-form",
        component: () =>
          import("@/views/websites/terms-condition/TermsConditionForm.vue"),
        meta: {
          pageTitle: "Form Syarat & Ketentuan",
          breadcrumbs: ["Website", "Form Syarat & Ketentuan"],
        },
      },
      {
        path: "/website/master-category-news",
        name: "website-master-category-news",
        component: () =>
          import(
            "@/views/websites/master-category-news/MasterCategoryNewsView.vue"
          ),
        meta: {
          pageTitle: "Data Master Kategori News",
          breadcrumbs: ["Property", "Data Master Kategori News"],
        },
      },
      {
        path: "/website/master-category-faq",
        name: "website-master-category-faq",
        component: () =>
          import(
            "@/views/websites/master-category-faq/MasterCategoryFAQView.vue"
          ),
        meta: {
          pageTitle: "Data Master Kategori FAQ",
          breadcrumbs: ["Property", "Data Master Kategori FAQ"],
        },
      },
      //REPORT ROUTE
      {
        path: "/report/total-property-city",
        name: "report-total-property-city",
        component: () =>
          import(
            "@/views/reports/total-property-city/TotalPropertyCityView.vue"
          ),
        meta: {
          pageTitle: "Rekap Total Property Per Kota",
          breadcrumbs: ["Laporan", "Rekap Total Property Per Kota"],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
    meta: {
      pageTitle: "Multi-step Sign up",
    },
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle || "Bizniz Property"} - ${
    process.env.VUE_APP_NAME
  }`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // verify auth token before each page change
  var checkAuth = await store.dispatch(Actions.VERIFY_AUTH, {
    api_token: JwtService.getToken(),
  });

  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    if (store.getters.isUserAuthenticated) {
      const appStore = useAppStore();
      await appStore.fetchAccesses();
      if (to.path == "/") {
        const firstMenu = appStore.menus[0]?.firstRoute;
        if (firstMenu) {
          next(firstMenu);
        }
      }

      next();
    } else {
      next({ name: "sign-in" });
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
