import axios from "axios";
import { MP_API_URL } from "../config/AppConst";
import JwtService from "@/core/services/JwtService";
import router from "@/router";

const apiClient = axios.create({
  baseURL: MP_API_URL,
});

function setHeader() {
  if (JwtService.getToken()) {
    apiClient.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  } else {
    delete apiClient.defaults.headers.common["Authorization"];
  }
}

async function handleApiCall(apiCallFunction) {
  try {
    const response = await apiCallFunction();
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      await router.push({ name: "sign-in" });
    } else {
      throw error;
    }
  }
}

export async function get(endpoint) {
  setHeader();
  console.log("endpoint", endpoint);
  return handleApiCall(() => apiClient.get(endpoint));
}

export async function post(endpoint, data) {
  setHeader();
  console.log("endpoint", endpoint);
  console.log("data", data);
  return handleApiCall(() => apiClient.post(endpoint, data));
}

export async function del(endpoint) {
  setHeader();
  console.log("endpoint", endpoint);
  return handleApiCall(() => apiClient.delete(endpoint));
}

export async function put(endpoint, data) {
  setHeader();
  console.log("endpoint", endpoint);
  console.log("data", data);
  return handleApiCall(() => apiClient.put(endpoint, data));
}

export async function patch(endpoint, data) {
  setHeader();
  console.log("endpoint", endpoint);
  console.log("data", data);
  return handleApiCall(() => apiClient.patch(endpoint, data));
}
