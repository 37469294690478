import { defineStore } from "pinia";
import { get } from "@/core/helpers/api_mp";
import accessData from "@/core/config/access.json";

interface Module {
  id: string;
  name: string;
  order: number;
}

interface SubModule {
  id: string;
  name: string;
  order: number;
}

interface Menu {
  id: string;
  name: string;
  order: number;
  module: Module;
  sub_module: SubModule;
}

interface GroupedData {
  [key: string]: {
    module: Module;
    subModules: {
      [key: string]: {
        subModule: SubModule;
        menus: Menu[];
      };
    };
  };
}

export const useAppStore = defineStore("app", {
  state: () => ({
    menus: [] as Menu[],
    menus2: [] as any[], // Anda bisa mengganti 'any' dengan tipe data yang lebih spesifik
  }),
  getters: {
    getAccesses(state) {
      return state.menus;
    },
  },
  actions: {
    async fetchAccesses() {
      this.menus = JSON.parse(JSON.stringify(accessData.data));

      //check jika ada local storage access maka tidak perlu fetch lagi
      // const cachedAccess = localStorage.getItem("access");
      // if (cachedAccess && JSON.parse(cachedAccess).length > 0) {
      //   this.menus2 = JSON.parse(cachedAccess);
      //
      //   return;
      // } else {
      const groupData = (menus: Menu[]): { modules: any[] } => {
        const groupedData: GroupedData = menus.reduce(
          (acc: GroupedData, item: Menu) => {
            if (!acc[item.module.id]) {
              acc[item.module.id] = {
                module: item.module,
                subModules: {},
              };
            }

            if (!acc[item.module.id].subModules[item.sub_module.id]) {
              acc[item.module.id].subModules[item.sub_module.id] = {
                subModule: item.sub_module,
                menus: [],
              };
            }

            acc[item.module.id].subModules[item.sub_module.id].menus.push(item);

            return acc;
          },
          {}
        );

        const sortedModules = Object.values(groupedData).sort(
          (a, b) => a.module.order - b.module.order
        );

        return {
          modules: sortedModules.map((moduleGroup) => {
            const sortedSubModules = Object.values(moduleGroup.subModules).sort(
              (a, b) => a.subModule.order - b.subModule.order
            );
            return {
              ...moduleGroup.module,
              subModules: sortedSubModules.map((subModuleGroup) => {
                const sortedMenus = subModuleGroup.menus.sort(
                  (a, b) => a.order - b.order
                );
                return {
                  ...subModuleGroup.subModule,
                  menus: sortedMenus,
                };
              }),
            };
          }),
        };
      };

      const menus = await get("menus?limit=1000");

      const groupedData = groupData(menus.data.rows).modules;

      this.menus2 = groupedData; // Tidak perlu @ts-ignore jika tipe data sudah sesuai

      // localStorage.setItem("access", JSON.stringify(groupedData));
      // }
    },
  },
});
